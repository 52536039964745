@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

.video-editor-desktop {
    display: flex;
    flex-direction: row;
    justify-content: center;

    border: 1px solid #D2D2D6;

    width: 1000px !important;
    height: 630px !important;

    @media (max-width: 1300px) {
        display: none !important;
    }
}

.video-editor-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;

    border: 1px solid #D2D2D6;

    width: 333px !important;
    height: 210px !important;

    @media (min-width: 1300px) {
        display: none !important;
    }
}
